export const BASE_API_URL =
  process.env.REACT_APP_STAGE === "production"
    ? "//aplikacja-api.gtvbus.pl"
    : "https://api.mobile.sandbox.gtv.cloud.codelabs.work"; //"https://vps-89cdeb36.vps.ovh.net/api/web";

export const URL_GET_WORKERS = '/company/employees';

// endpoints related to CARS
export const URL_GET_CARS = "/routes/search";
export const URL_GET_NAVI_CARS = "/routes/navi";
export const URL_GET_NAVI_COMAPNY_CARS = "/routes/navi-car";
export const URL_GET_ALL_NAVI_CARS = "/routes/search-navi";

// endpoints related to ROUTE
export const URL_GET_ROUTE = "routes/points";
export const URL_GET_ROUTE_DETAILS = "routes/details";
export const URL_SAVE_NEW_ROUTE = "/routes/edit-points";
export const URL_GET_COMPANIES_DESTINATIONS = "/company/end-flags";

// endpoints related to SETTINGS
export const URL_SETTINGS = "/configs";
export const URL_BASIC_CONFIG = "/configs/base";

// endpoints related to USERS
export const URL_LOGIN = "/users/login";
export const URL_LOGOUT = "/users/logout";
export const URL_DATABASES = "/users/dbs";

// endpoints related to EVENTS
export const URL_GET_EVENTS = "/alerts";

// endpoints related with COMPANIES
export const URL_GET_COMPANIES = "/company/account-list";
export const URL_GET_COMPANY_CARS = "/company/car-list";

// outer endpoint for route data for Here API
// export const URL_GET_ROUTE_FROM_HERE_API = "https://route.ls.hereapi.com/routing/7.2/calculateroute.json";
export const URL_GET_ROUTE_FROM_HERE_API = "https://router.hereapi.com/v8/routes";

// endpoints related to COMPANIES
export const URL_GET_CORPS = '/company/list';
export const URL_REMOVE_COMPANY = '/company/account-delete';
export const URL_CREATE_COMPANY = '/company/account-create';
export const URL_EDIT_COMPANY = '/company/account-edit';
export const URL_GET_COMPANY_TEAMS = '/company/team-list';

//endpoints related to FILTERS
export const URL_GET_SAVED_FILTERS = '/users/filters';
